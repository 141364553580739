import React, { useEffect, useReducer } from 'react';
import fetch from 'isomorphic-fetch'

import './App.css';
const test = false
const servicesUrl = test ? `http://localhost:3000` : `https://m570gzyn6h.execute-api.us-east-1.amazonaws.com/production/`
const formUrl = test ?  `https://giftwp-test.escsportsapi.com/form/load` : `https://giftwp.escsportsapi.com/form/load`
const submitUrl = test ? `https://giftwp-test.escsportsapi.com/submission/save` : `https://giftwp.escsportsapi.com/submission/save`


function getExt(fileName){
	return (fileName.lastIndexOf('.') < 1) ? null : fileName.split('.').pop();
}

function readFile(file, func) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader[func](file)
		reader.onload = () => {
			resolve(reader.result)
		}
		reader.onerror = (e) => {
			reader.abort()
			reject(e)
		}
	})
}

function App() {
	const initialState = { form: null, loading: true, error: null, success: false }
	const [state, dispatch] = useReducer((_, action) => action, initialState)

	async function hanldeSubmit(body) {
		console.log(`Body Sent: `, JSON.stringify(body, null, 2))

		let gwpRes = await fetch(servicesUrl, {
			method: `post`,
			body: JSON.stringify({
				url: submitUrl,
				data: body
			})
		})

		try {
			gwpRes = await gwpRes.text()
			console.log(JSON.parse(gwpRes))
			const { sent } =  JSON.parse(gwpRes)
			if(sent && sent.success) {
				dispatch({ loading: false, success: true })
			} else {
				dispatch({ loading: false, error: `Form was not able to submit, please try again.` })
			}
		} catch(e){
			throw new Error(e)
		}
	}

	useEffect(() => {
		async function fetchForm (){
			try {
				dispatch({loading: true})
				let res = await fetch(servicesUrl, {
					method: `post`,
					body: JSON.stringify({
						url: formUrl,
						data: {
							url: `gift.stigaus.com`
						}
					})
				})
				res = await res.text()
				const { form } = JSON.parse(res)
				if(form){
					dispatch({form, loading: false})

					const formWrapper = document.querySelector(`#gwp-form-wrapper`)
					const formEl = formWrapper.querySelector(`form`)
					if(formEl){
						formEl.addEventListener(`submit`, async e => {
							dispatch({loading: true})
							e.preventDefault()

							let fd = new FormData(formEl);
							let body = {}
							for(let [key, value] of fd.entries()){
								body[key] = value
							}
							body.form_id = formEl.id
							if(body.receipt){
								body.receipt_ext = getExt(body.receipt.name)
								body.receipt = await readFile(body.receipt, `readAsDataURL`)
							}
							if(body.ccnum && body.ccmon && body.ccyear && body.cccode) {
								body.card = {
									number: body.ccnum,
									expire: {
										month: body.ccmon,
										year: body.ccyear
									},
									code: body.cccode
								}
							}

							hanldeSubmit(body)
						})
					}
				} else {
					dispatch({error: `There was a problem loading the form, please try again.`, loading: false})
				}
			} catch(e){
				dispatch({loading: false, error: `${e}`})
			}
		}

		fetchForm()
	}, [])

	function renderContent() {
		const { form, error } = state
		if(state.loading) return <div>Loading...</div>
		if(state.error) return <div>Something went wrong: {error} <button onClick={() => document.location.reload()}>Reload</button></div>
		if(state.success) return <div>The form was successfully submitted!</div>
		if(state.form) return <div className="form" dangerouslySetInnerHTML={{__html: form}} />
		return <div>Form Not Found</div>
	}

	return (
    <div className="App">
      <header className="App-header">
				<img src="logo.jpg" alt="Stiga Logo" />
        <h1>Gift with Purchase Portal</h1>
				{renderContent()}
      </header>
    </div>
  );
}

export default App;
